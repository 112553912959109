/* AiOverviewModal.module.css */
.dialogTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  color: #fff;
}

.dialogContent {
  background-color: #292929;
  color: #fff;
  padding: 20px;
}

.dialogContent h5, h6 {
  color: #B1F938;
  margin-top: 20px;
}

.dialogContent table {
  background-color: #333;
  color: #fff;
}

.dialogContent th {
  background-color: #444;
  color: #fff;
}

.dialogContent td {
  border-color: #555;
}