/* src/components/Dashboard/Dashboard.module.css */

.dashboard {
  flex-grow: 1;
  /* background-color: #121212; */
  background-color: transparent;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.initialView {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  padding: 24px;
  margin-top: 10vh;
}

@media (max-width: 768px) {
  .initialView {
    margin-top: 0;
  }
}

.title {
  color: #fff;
  margin-bottom: 16px;
  text-align: center;
}

.subtitle {
  color: #ccc;
  margin-bottom: 32px !important;
  text-align: center;
}

.searchCard {
  width: 100%;
  max-width: 1200px;
  background-color: #1E1E1E;
  margin: 0 auto;
}

.searchCardBulks {
  width: 100%;
  background-color: #1E1E1E;
  margin: 0 auto;
}

.keywordInput {
  background-color: #2C2C2C;
}

.keywordInput input {
  color: #CCC;
}

.keywordInput fieldset {
  border-color: #333;
}

.keywordInput:hover fieldset {
  border-color: #444;
}

.keywordInput.Mui-focused fieldset {
  border-color: #666;
}

.searchButton {
  height: 56px;
}

.addButton {
  height: auto;
}

.cancleButton {
  background: #595959;
  color: #fff;
}

.resultView {
  flex-grow: 1;
  overflow: auto;
  padding: 24px;
  display: flex;
  flex-direction: column;
}

.searchBar {
  margin-bottom: 32px;
  background-color: #1E1E1E;
}

.searchBarContent {
  display: flex;
  align-items: center;
  gap: 16px;
}

.searchBarContent .keywordInput {
  flex-grow: 1;
}

.searchIcon {
  color: #666;
}

.keywordInfo {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  /* Change this from flex-end to flex-start */
  margin-bottom: 32px;
}

/* Add this new class */
.keywordInfoLeft {
  text-align: left;
}

.keywordTitle {
  color: #fff;
  margin-bottom: 8px;
}

.keywordVolume {
  color: #ccc;
  padding-left: 5px;
}

.countryFlag {
  width: 1.5em;
  height: 1.5em;
  margin-right: 10px;
  vertical-align: middle;
}

.actionButtons {
  display: flex;
  gap: 8px;
}

.actionButton {
  color: #fff;
  font-size: 2rem !important;
}

.scoreCards {
  margin-bottom: 32px;
}

.dataTables {
  margin-bottom: 32px;
}

.tableContainer {
  width: 100%;
}

.tableCard {
  background-color: #1E1E1E;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: 100%;
}

.keywordIdeasCard {
  background-color: #1E1E1E;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.keywordIdeasTitle {
  margin-bottom: 16px;
  color: #fff;
}

.loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.dialogCancelButton {
  background-color: #222;
  color: #fff;
}

@media (max-width: 768px) {
  .searchBarContent {
    flex-direction: column;
  }

  .searchBarContent>* {
    width: 100%;
    margin-bottom: 16px;
  }

  .keywordInfo {
    flex-direction: column;
    align-items: flex-start;
  }

  .actionButtons {
    margin-top: 16px;
  }
}