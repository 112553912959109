/* src/components/Category/CategoryDashboard.module.css */
.dashboard {
    /* background-color: #121212; */
    /* min-height: 100vh; */
    padding: 24px;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
}

.searchBarCenter {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
}

.searchBarTop {
    margin-bottom: 24px;
}

.searchCard {
    width: 100%;
}

.categoryInput {
    background: #2C2C2C !important;
    width: 100%;
}

.inputGrid {
    padding-top: 0 !important;
}

.categoryInput .MuiOutlinedInput-root {
    background-color: #2C2C2C;
}

.categoryInput .MuiOutlinedInput-notchedOutline {
    border-color: #444;
}

.categoryInput:hover .MuiOutlinedInput-notchedOutline {
    border-color: #666;
}

.categoryInput.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #fff;
}

.categoryInput input {
    color: #fff;
}

.searchIcon {
    color: #999;
}

.searchButton {
    height: 56px;
    background-color: #9333EA !important;
}

.searchButton {
    height: 56px;
    /* Match the height of the Autocomplete input */
    background-color: #9333EA !important;
}

.tableContainer {
    /* Set a fixed height */
    overflow-y: auto;
    /* Enable vertical scrolling */
}

.tableRow {
    height: 60px;
    /* Set a fixed height for each row */
}

.tableContainer table {
    table-layout: fixed;
    /* Ensure consistent column widths */
    width: 100%;
}

.customScrollbar {

    /* Styles for Chrome, Edge, and Safari */
    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-track {
        background: #2C2C2C;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #9333EA;
        border-radius: 20px;
        border: 2px solid #2C2C2C;
    }

    /* Styles for Firefox */
    scrollbar-width: thin;
    scrollbar-color: #9333EA #2C2C2C;
}

@media (max-width: 600px) {
    .searchCard {
        max-width: 100%;
    }

    .cardContent {
        padding: 12px !important;
    }

    .categoryInput {
        font-size: 14px;
    }

    .searchButton {
        height: 40px;
        font-size: 14px;
    }

        .inputGrid {
            padding-left: 0 !important;
        }
}
.chipContainer {
    margin-top: 16px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.popularChip {
    background-color: #9333EA;
    color: #fff;

    &:hover {
        background-color: #7B2CBF;
    }
}