.scoreCard {
  height: 100%;
  background-image: unset !important;
  background-color: #222 !important;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  position: relative;
}

.cardContent {
  height: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15px;
}

.title {
  color: #999;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.infoButton {
  color: #666;
  padding: 0;
  margin-left: 5px;
  opacity: 0.3;
}

.editButton {
  color: #fff;
  padding: 4px;
}

.body {
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
}

.leftContent {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-right: 5px;
}

.leftContent > * {
  margin-bottom: 20px !important;
}

.rightContent {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: 35%;
}

.value {
  color: #fff;
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 8px;
  text-align: left;
}

.primaryCategoryValue {
  margin-top: 0;
}

.subheadingContainer {
  margin-bottom: 16px;
  text-align: left;
}

.subheading {
  font-size: 16px;
  font-weight: 500;
  padding: 2px 8px;
  margin-bottom: 4px;
  border-radius: 4px;
  display: inline-block;
}

.lowerValue {
  color: #fff;
  font-size: 14px;
  padding: 2px 8px;
  text-align: left;
}

.free {
  background-color: #4ECB71;
  color: #222;
}

.paid {
  background-color: #9F42FF;
  color: #fff;
}

.subcategories {
  color: #fff;
  font-size: 14px;
  margin-top: 4px;
  padding-right: 10px;
  text-align: left;
}

.scoreCircle {
  width: 100%;
  height: 100%;
}

.scoreValue {
  fill: #fff;
  font-size: 28px;
  font-weight: 700;
}

.scoreMaxValue {
  fill: #999;
  font-size: 12px;
}

.categoryTooltip {
  position: fixed;
  background-color: rgba(30, 30, 30, 0.9);
  color: #fff;
  padding: 8px;
  border-radius: 4px;
  z-index: 1000;
  pointer-events: none;
  backdrop-filter: blur(3px);
}

.categorySlice {
  transition: fill 0.3s ease;
}

.categorySlice:hover {
  fill: #9333EA;
}

.categoryGap {
  pointer-events: none;
}

.primaryCategoryHoverArea {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

@media (max-width: 768px) {
  .value {
    font-size: 24px;
  }

  .scoreValue {
    font-size: 24px;
  }

  .scoreMaxValue {
    font-size: 10px;
  }
}