/* BulkChecks.module.css */
.bulkChecksContainer {
    padding: 24px;
    max-width: 800px;
    margin: 0 auto;
}

.title {
    color: #fff;
    margin-bottom: 8px;
    text-align: center;
}

.subtitle {
    color: #ccc;
    margin-bottom: 20px;
    text-align: center;
}

.bulkChecksCard {
    background-color: #161616 !important;
    border-radius: 8px;
    margin-top: 20px;
}

.keywordsInputContainer {
    position: relative;
    margin-bottom: 16px;
}

.keywordsInput {
    background-color: #2C2C2C;
}

.keywordsInputField {
    color: #CCC;
    height: 150px !important;
    overflow: hidden !important;
}

.keywordsInputField textarea {
    padding-top: 0 !important;
    margin-top: -5px !important;
    padding-left: 33px !important;
    height: 100% !important;
}

.keywordsInputField input::placeholder {
    line-height: 1.5;
    vertical-align: middle;
}

.searchIconWrapper {
    position: absolute;
    top: 16px;
    left: 12px;
    height: 20px;
    pointer-events: none;
    z-index: 1;
    display: flex;
    align-items: center;
    height: 1em !important;
}

.searchIcon {
    color: #666;
    font-size: 20px;
    height: 1em;
}

.keywordChips {
    position: absolute;
    top: 48px;
    left: 14px;
    right: 14px;
    bottom: 14px;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    padding-right: 8px;
}

.keywordChips::-webkit-scrollbar {
    width: 8px;
}

.keywordChips::-webkit-scrollbar-track {
    background: #1E1E1E;
}

.keywordChips::-webkit-scrollbar-thumb {
    background-color: #9333EA;
    border-radius: 4px;
}

.keywordChip {
    /* background-color: #9333EA !important; */
    background-color: #ffffff2f !important;
    color: white !important;
    margin: 4px 2px !important;
    max-width: calc(100% - 4px) !important;
    transition: 350ms;
}

.keywordChip:hover {
    background-color: #ffffff55 !important;
    transition: 350ms;
}


.keywordCount {
    color: #999;
    display: block;
    text-align: right;
    margin-top: -7px !important;
    margin-bottom: 10px !important;
}

.selectors {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    margin-bottom: 16px;
}

.selector {
    width: 100%;
}

.analyzeButton {
    width: 100%;
    background-color: #9333EA;
    color: white;
    height: 48px;
    margin-top: 10px !important;
}

.analyzeButton:hover {
    background-color: #7B2CBF;
}

.analyzeButton:disabled {
    background-color: #4A148C;
    color: #999;
}

.errorMessage {
    color: #ff6b6b;
    display: block;
    text-align: center;
    text-decoration: none;
    margin-top: 10px !important;
    /* padding-bottom: 10px !important;
    margin-bottom: 10px !important;
    min-height: 1em; */
}

.projectSelector {
    background-color: #2C2C2C !important;
}

.projectSelector .MuiSelect-select {
    color: #CCC !important;
}

.projectSelector .MuiOutlinedInput-notchedOutline {
    border-color: rgba(255, 255, 255, 0.23) !important;
}

.projectSelector:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgba(255, 255, 255, 0.87) !important;
}

.projectSelector.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #9333EA !important;
}


@media (max-width: 600px) {
    .selectors {
        grid-template-columns: 1fr;
    }
}

.selectors {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 16px;
  }
  
  .fullWidthSelector {
    width: 100%;
  }
  
  .halfWidthSelectors {
    display: flex;
    gap: 16px;
  }
  
  .halfWidthSelectors > * {
    flex: 1;
  }
  
  @media (max-width: 600px) {
    .halfWidthSelectors {
      flex-direction: column;
    }
  }