/* toastify-custom.css */

.Toastify__toast {
    background-color: #222 !important;
    color: #fff !important;
}

.Toastify__close-button {
    color: #fff !important;
}

.Toastify__progress-bar {
    background-color: #9C27B0 !important;
}